import { Component, HostListener, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { icons } from 'src/assets/icon/svg';
import { SafeHtmlPipe } from 'src/app/shared/pipes/safe-html.pipe';
import { ImagePreviewService } from '../../services/image-preview.service';
import { validImageExtensions } from 'src/app/feature/requests/interfaces/requests-interface';
import { AlertService } from '../../services/alert.service';

@Component({
  selector: 'image-preview',
  standalone: true,
  imports: [CommonModule, SafeHtmlPipe],
  templateUrl: './image-preview.component.html',
  styleUrls: ['./image-preview.component.scss'],
})
export class ImagePreviewComponent {
  @Input() set src(value: any[]) {
    this.images = value.map(image => ({ ...image, rotation: 0 }));
  }

  images: any[] = [];
  icons = icons;
  validImageExtensions = validImageExtensions;

  @HostListener('document:keydown.escape', ['$event']) onKeydownHandler(
    event: KeyboardEvent
  ) {
    this.closePreview();
  }

  constructor(
    private imagePreviewService: ImagePreviewService,
    private alertService: AlertService
  ) {}

  closePreview() {
    this.imagePreviewService.previewImage(null);
  }

  download() {
    const current = this.images.find(image => image.isActive);
    if (current) {
      const secureUrl = current.url.replace('http://', 'https://');
  
      fetch(secureUrl, {
        mode: 'cors',
      })
      .then(response => {
        if (!response.ok) {
          throw new Error(`Network response was not ok: ${response.statusText}`);
        }
        console.log('response.blob() :>> ', response.blob());
        return response.blob();
      })
      .then(blob => {
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = 'image';  // Set filename if needed
        a.click();
        window.URL.revokeObjectURL(url);
      })
      .catch((error) => {
        console.error('Error during download:', error); // Detailed error logging
        this.alertService.error('Error Downloading File');
      });
    } else {
      this.alertService.error('Error Downloading File');
    }
  }  
  
  next(curIndex: number) {
    this.images[curIndex].isActive = false;
    if (validImageExtensions.includes(this.images[curIndex + 1].type)) {
      this.images[curIndex + 1].isActive = true;
    } else {
      this.images[curIndex + 1].isActive = true;
    }
  }

  previous(curIndex: number) {
    this.images[curIndex].isActive = false;
    if (validImageExtensions.includes(this.images[curIndex - 1].type)) {
      this.images[curIndex - 1].isActive = true;
    } else {
      this.images[curIndex - 1].isActive = true;
    }
  }

  viewPdf(url: string) {
    window.open(url);
  }

  rotateImage(index: number) {
    const image = this.images[index];
    image.rotation = (image.rotation + 90) % 360;
  }
}
